import client from '../request'
import _ from 'lodash'

const getAllPending = () => {
  return client.get(`/notifications/pending/count`)
}

const getIncome = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (key == 'status' && value == -1) {
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }
  return client.get(`/notifications/income?${filterString}`)
}

const getStats = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (key == 'status' && value == -1) {
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }
  return client.get(`/notifications/stats?${filterString}`)
}

const getNewStatistic = (props) => {
  let from_date = props?.from_date ?? ''
  let to_date = props?.to_date ?? ''
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (key == 'status' && value == -1) {
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }

  // console.log('propsprops', props)
  // console.log(`/admin_dashboard_statistics?from_date=${from_date}&to_date=${to_date}`)
  // return client.get(`/me/clients?page=${page}&limit=${limit}&from_date=${from_date}&to_date=${to_date}${filterString}`)
  return client.get(`/admin_dashboard_statistics?from_date=${from_date}&to_date=${to_date}`)
}

const getMonthlyStatistic = (props) => {
  let year = props?.year ?? 2021
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (key == 'status' && value == -1) {
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }
  return client.get(`/admin_dashboard_statistics_monthly?year=${year}`)
}

const getDailyStatistic = (props) => {
  let month = props?.month ?? '2021-09'
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (key == 'status' && value == -1) {
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }
  return client.get(`/admin_dashboard_statistics_daily?month=${month}`)
}

const getAuditPanelDeposit = (props) => {
  // let from_date = props?.from_date ?? ''
  // let to_date = props?.to_date ?? ''
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (key == 'status' && value == -1) {
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }
  return client.get(`/audit_panel_deposit?${filterString}`)
}

export default {
  getAllPending,
  getIncome,
  getStats,
  getNewStatistic,
  getMonthlyStatistic,
  getDailyStatistic,
  getAuditPanelDeposit
}
